@import '../fonts/c4c-icon-v1.0/style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-violet {
	--background: 0 0% 100%;
	--foreground: 224 71.4% 4.1%;
	--muted: 220 14.3% 95.9%;
	--muted-foreground: 220 8.9% 46.1%;
	--popover: 0 0% 100%;
	--popover-foreground: 224 71.4% 4.1%;
	--card: 0 0% 100%;
	--card-foreground: 224 71.4% 4.1%;
	--border: 220 13% 91%;
	--input: 220 13% 91%;
	--primary: 262.1 83.3% 57.8%;
	--primary-foreground: 210 20% 98%;
	--secondary: 220 14.3% 95.9%;
	--secondary-foreground: 220.9 39.3% 11%;
	--accent: 220 14.3% 95.9%;
	--accent-foreground: 220.9 39.3% 11%;
	--destructive: 0 84.2% 60.2%;
	--destructive-foreground: 210 20% 98%;
	--ring: 262.1 83.3% 57.8%;
	--radius: ;
}

.dark .theme-violet {
	--background: 224 71.4% 4.1%;
	--foreground: 210 20% 98%;
	--muted: 215 27.9% 16.9%;
	--muted-foreground: 217.9 10.6% 64.9%;
	--popover: 224 71.4% 4.1%;
	--popover-foreground: 210 20% 98%;
	--card: 224 71.4% 4.1%;
	--card-foreground: 210 20% 98%;
	--border: 215 27.9% 16.9%;
	--input: 215 27.9% 16.9%;
	--primary: 263.4 70% 50.4%;
	--primary-foreground: 210 20% 98%;
	--secondary: 215 27.9% 16.9%;
	--secondary-foreground: 210 20% 98%;
	--accent: 215 27.9% 16.9%;
	--accent-foreground: 210 20% 98%;
	--destructive: 0 62.8% 30.6%;
	--destructive-foreground: 210 20% 98%;
	--ring: 263.4 70% 50.4%;
}
h1 {
	font-family: var(--beVietnamSans-font);
}

.btn {
	font-family: var(--beVietnamSans-font);
}
/* Ẩn thanh scroll ban đầu */
::-webkit-scrollbar {
	width: 8px;
}

/* Tạo một track (đường dẫn cho thanh scroll) */
::-webkit-scrollbar-track {
	background-color: transparent; /* Màu nền cho track */
	/*border-radius: 5px; !* Bo tròn các góc *!*/
	/*opacity: 0;*/
	/*position: absolute;*/
}

/* Tạo một handle (thanh cuộn) */
::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

/* Hiển thị thanh scroll khi hover */
::-webkit-scrollbar:hover {
	width: 12px; /* Kích thước thanh scroll khi hover */
}
::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}

/* Bỏ mũi tên tăng giảm số */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}

@font-face {
	font-family: 'iCiel_Crocante';
	src: url('../fonts/iCiel_Crocante/iCiel_Crocante.otf') format('opentype');
	font-display: swap;
}

@layer components {
	.input-default {
		@apply focus: m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:outline-none;
	}

	.btn-style {
		@apply border-none active:translate-y-px;
	}
	.btn-lang {
		@apply hover:bg-gray-1 text-gray-9 w-full rounded-md bg-transparent px-2 py-1 text-xs font-medium duration-200;
	}
	.btn-lang-active {
		@apply !from-primary-5 !to-primary-4 !bg-gradient-to-l !text-white;
	}
	.center {
		@apply flex items-center justify-center;
	}

	.row {
		@apply flex flex-row items-center;
	}

	.col {
		@apply flex flex-col;
	}

	.row-center {
		@apply flex flex-row items-center justify-center;
	}

	.row-between {
		@apply flex flex-row items-center justify-between;
	}

	.animation {
		@apply transition-all duration-300 ease-linear;
	}

	.bg-gradient-default {
		@apply from-cyan-1 to-cyan-2 bg-gradient-to-b;
	}
	.text-gradient-default {
		@apply from-cyan-1 to-cyan-2 bg-gradient-to-r bg-clip-text text-transparent;
	}
	.bg-gradient-3 {
		@apply from-primary-4 to-primary-5 bg-gradient-to-b;
	}
	.text-gradient-3 {
		@apply from-primary-4 to-primary-5 bg-gradient-to-r bg-clip-text text-transparent;
	}
	.bg-gradient-gold {
		@apply from-yellow-4 to-yellow-3 bg-gradient-to-b;
	}
}

.bg-gradient-secondary {
	background: linear-gradient(
		34.25deg,
		#ff003b 13.23%,
		#ffda7c 94.3%,
		#ccff00 94.3%
	);
}

.rdw-image-modal-alt-input {
	color: #333333 !important;
}
.rdw-image-modal-size-input {
	color: #333333 !important;
}
.rdw-image-modal-btn {
	color: #333333 !important;
}

.ant-select-selector {
	background: transparent !important;
	border: none !important;
}
.ant-select-selection-search-input {
	height: 46px !important;
}

.ant-select-selection-item {
	color: white;
}

.ant-qrcode {
	padding: 0 !important;
}

div > .ant-tabs-top > .ant-tabs-nav::before {
	border-bottom-width: 0;
}

div > .ant-tabs .ant-tabs-ink-bar {
	background: #fff;
	margin-bottom: 6px;
}

/*custom assets for table in Antd*/
div#table-custom > .ant-table-wrapper .ant-table {
	border-radius: 2px !important;
	overflow: hidden;
	background-color: transparent !important;
}

/* Custom scroll for Table*/
.ant-table-content::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.ant-table-content::-webkit-scrollbar-track {
	margin-top: 49px;
	border-radius: 10px;
	margin-bottom: 1px;
	background: transparent;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
	background: #242424;
	border-radius: 10px;
}

.ant-table-thead {
	position: sticky;
	top: 0;
	z-index: 99;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container {
	border-inline-start: 1px solid rgba(255, 255, 255, 0.2) !important;
	border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

div#table-custom > .ant-table-wrapper .ant-table-container {
	border-radius: 2px !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th {
	background-color: transparent !important;
	color: white !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th {
	border-inline-end: 1px solid rgba(255, 255, 255, 0.2) !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td {
	border-inline-end: 1px solid rgba(255, 255, 255, 0.2) !important;
}

div#table-custom > .ant-table-wrapper .ant-table-thead > tr > th {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

div#table-custom > .ant-table-wrapper .ant-table-tbody > tr > td {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:first-child {
	border-start-start-radius: 0 !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:last-child {
	border-end-end-radius: 0 !important;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:last-child {
	border-start-end-radius: 0 !important;
}
div#table-custom
	> .ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th {
	border-inline-end: 1px solid rgba(255, 255, 255, 0.2) !important;
}

/* Data empty in Table */
div#table-custom
	> .ant-table-wrapper
	.ant-table-tbody
	> tr.ant-table-placeholder {
	background-color: transparent !important;
}

.ant-empty-normal .ant-empty-description {
	color: #fff !important;
}

/* Hover for table */
div#table-custom
	> .ant-table-wrapper
	.ant-table-tbody
	> tr.ant-table-row:hover
	> td {
	@apply bg-white/20;
}

div#table-custom
	> .ant-table-wrapper
	.ant-table-tbody
	> tr.ant-table-placeholder:hover
	> td {
	background-color: transparent !important;
}

/* ========================================== */

.lucky-number-spinner {
	text-align: center;
	margin-top: 20px;
}

.number-display {
	display: flex;
	justify-content: center;
	align-items: center;
}

.number {
	font-size: 36px;
	font-weight: bold;
	margin: 0 10px;
	width: 50px;
	height: 50px;
	background-color: #007bff;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transform-style: preserve-3d;
	perspective: 1000px;
	transition: transform 0.5s;
}

.number.spinning {
	transform: rotateY(180deg);
}

.hide-toolbar {
	display: none !important;
}

.hide-toolbar .ql-toolbar {
	padding: 0 !important;
	border-bottom: hidden !important;
	border: none !important;
}

.hide-toolbar .ql-toolbar > span {
	display: none !important;
}

.hide-toolbar .ql-container {
	border: none !important;
}

.hide-toolbar .ql-editor {
	padding: 0 !important;
}
